import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/icons';
import { socialMedia } from '@config';

const StyledFooter = styled.footer`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  height: auto;
  min-height: 70px;
  padding: 15px;
  text-align: center;
`;

const StyledSocialLinks = styled.div`
  display: none;

  @media (min-width: 769px) { /* Changed to min-width if you want to show on larger screens */
    display: block;
    width: 100%;
    max-width: 270px;
    margin: 0 auto 10px;
    color: var(--light-slate);
  }

  ul {
    ${({ theme }) => theme.mixins.flexBetween};
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      a {
        padding: 10px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;

const StyledCredit = styled.div`
  color: var(--light-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xxs);
  line-height: 1;

  a {
    color: inherit; /* Ensure the link inherits the text color */
    text-decoration: none;
    padding: 10px;
  }

  .github-stats {
    margin-top: 10px;

    & > span {
      display: inline-flex;
      align-items: center;
      margin: 0 7px;
    }
    svg {
      display: inline-block;
      margin-right: 5px;
      width: 14px;
      height: 14px;
    }
  }
`;

const Footer = () => {
  const [githubInfo, setGitHubInfo] = useState({
    stars: null,
    forks: null,
  });

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    // Replace 'owner' and 'repo' with your GitHub username and repository name
    fetch('')
      .then(response => response.json())
      .then(json => {
        const { stargazers_count, forks_count } = json;
        setGitHubInfo({
          stars: stargazers_count,
          forks: forks_count,
        });
      })
      .catch(e => console.error('Error fetching GitHub data:', e));
  }, []);

  return (
    <StyledFooter>
      <StyledSocialLinks>
        <ul>
          {socialMedia &&
            socialMedia.map(({ name, url }, i) => (
              <li key={i}>
                <a href={url} aria-label={name} target="_blank" rel="noopener noreferrer">
                  <Icon name={name} />
                </a>
              </li>
            ))}
        </ul>
      </StyledSocialLinks>

      <StyledCredit tabIndex="-1">
  <div style={{ marginBottom: '6px' }}>
    Designed and Developed by Sharukhali Syed
  </div>
  <div>
    © {new Date().getFullYear()}
  </div>

        {githubInfo.stars !== null && githubInfo.forks !== null && (
          <div className="github-stats">
            <span>
              <Icon name="Star" />
              <span>{githubInfo.stars.toLocaleString()}</span>
            </span>
            <span>
              <Icon name="Fork" />
              <span>{githubInfo.forks.toLocaleString()}</span>
            </span>
          </div>
        )}
      </StyledCredit>
    </StyledFooter>
  );
};

export default Footer;