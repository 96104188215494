// src/components/AchievementsList.js

import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

// Styled Components

const StyledAchievementsSection = styled.section`
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem 1rem; /* Reduced padding for compactness */

  h2.numbered-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(24px, 5vw, var(--fz-heading));
    width: 100%;
    margin: 40px 0; /* Adjust margin as needed */

    /* Pseudo-elements for horizontal lines */
    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid SlateGray; /* Adjust color and thickness as needed */
      margin: 0 15px; /* Space between lines and text */
    }
  }


  .heading {
    text-align: center;
    margin-bottom: 2rem; /* Reduced margin */
    font-size: 2rem; /* Slightly smaller font size */
    position: relative;

    &::after {
      content: '';
      width: 50px; /* Slightly smaller line */
      height: 3px;
      background: var(--green);
      display: block;
      margin: 0.5rem auto 0;
      border-radius: 2px;
    }
  }
`;

const AchievementsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const AchievementItem = styled.li`
  background: var(--lightest-navy);
  margin-bottom: 0.75rem; /* Reduced margin */
  padding: 1rem 1.25rem; /* Reduced padding */
  border-radius: var(--border-radius);
  position: relative;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  overflow: hidden;

  &:hover,
  &:focus-within {
    background: var(--navy);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-left: 4px solid var(--green);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: var(--fz-md);
      font-weight: 600;
      color: var(--green);
    }

    .company {
      font-size: var(--fz-sm);
      color: var(--slate);
    }
  }

  .range-location {
    display: flex;
    justify-content: space-between;
    margin-top: 0.25rem; /* Reduced margin */

    .range {
      font-size: var(--fz-sm);
      color: var(--light-slate);
    }

    .location {
      font-size: var(--fz-sm);
      color: var(--light-slate);
    }
  }

  .description {
    margin-top: 0.5rem; /* Reduced margin */
    font-size: var(--fz-sm);
    color: var(--light-slate);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  &:hover .description,
  &:focus-within .description {
    max-height: 300px; /* Adjusted for compactness */
  }
`;

const AchievementsListComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      achievements: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/Volunteer/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              location
              range
              url
            }
            html
          }
        }
      }
    }
  `);

  const achievementsData = data.achievements.edges;

  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal('.achievement-item', srConfig());
  }, [prefersReducedMotion]);

  return (
    <StyledAchievementsSection id="achievements">
       <h2 className="numbered-heading">About Me</h2>
      <AchievementsList>
        {achievementsData.map(({ node }, index) => {
          const { title, company, location, range, url } = node.frontmatter;

          return (
            <AchievementItem
              key={index}
              className="achievement-item"
              tabIndex="0"
            >
              <div className="header">
                <h3 className="title">
                  {title}{' '}
                  <a
                    href={url}
                    className="inline-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @ {company}
                  </a>
                </h3>
              </div>
              <div className="range-location">
                <span className="range">{range}</span>
                <span className="location">{location}</span>
              </div>
              <div
                id={`achievement-details-${index}`}
                className="description"
                dangerouslySetInnerHTML={{ __html: node.html }}
              />
            </AchievementItem>
          );
        })}
      </AchievementsList>
    </StyledAchievementsSection>
  );
};

export default AchievementsListComponent;