// IconCustomCloud.jsx
import React from 'react';

const IconCustomCloud = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 64 64"
    fill="currentColor" // Adjust fill as needed
    className="icon icon-custom-cloud"
  >
    <title>Google Cloud</title>
    <path d="M48,24a12,12,0,0,0-24,0A10,10,0,0,0,8,34H4a2,2,0,0,0,0,4H8a14,14,0,0,0,14,14H48a14,14,0,0,0,0-28Z" />
  </svg>
);

export default IconCustomCloud;