import React, { useEffect, useRef, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import sr from '@utils/sr';
import { srConfig } from '@config';
import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';

// Existing Styled Components
const StyledNumberedHeading = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(24px, 5vw, var(--fz-heading));
  width: 100%;
  margin: 40px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid SlateGray;
    margin: 0 15px;
  }
`;

const StyledProjectsGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};

  a {
    position: relative;
    z-index: 1;
  }
`;

const StyledProject = styled.li`
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;

  @media (max-width: 768px) {
    ${({ theme }) => theme.mixins.boxShadow};
  }

  &:not(:last-of-type) {
    margin-bottom: 100px;

    @media (max-width: 768px) {
      margin-bottom: 70px;
    }

    @media (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  &:nth-of-type(odd) {
    .project-content {
      grid-column: 7 / -1;
      text-align: right;

      @media (max-width: 1080px) {
        grid-column: 5 / -1;
      }
      @media (max-width: 768px) {
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
        text-align: left;
      }
      @media (max-width: 480px) {
        padding: 25px 25px 20px;
      }
    }
    .project-tech-list {
      justify-content: flex-end;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      li {
        margin: 0 0 5px 20px;

        @media (max-width: 768px) {
          margin: 0 10px 5px 0;
        }
      }
    }

    .project-links {
      justify-content: flex-end;
      margin-left: 0;
      margin-right: -10px;

      @media (max-width: 768px) {
        justify-content: flex-start;
        margin-left: -10px;
        margin-right: 0;
      }
    }
    .project-image {
      grid-column: 1 / 8;

      @media (max-width: 768px) {
        grid-column: 1 / -1;
      }
    }
  }

  .project-content {
    position: relative;
    grid-column: 1 / 7;
    grid-row: 1 / -1;

    @media (max-width: 1080px) {
      grid-column: 1 / 9;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      grid-column: 1 / -1;
      padding: 40px 40px 30px;
      z-index: 5;
    }

    @media (max-width: 480px) {
      padding: 30px 25px 20px;
    }
  }

  .project-overline {
    margin: 10px 0;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
  }

  .project-title {
    color: var(--lightest-slate);
    font-size: clamp(24px, 5vw, 28px);

    @media (min-width: 768px) {
      margin: 0 0 20px;
    }

    @media (max-width: 768px) {
      color: var(--white);

      a {
        position: static;

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .project-description {
    ${({ theme }) => theme.mixins.boxShadow};
    position: relative;
    z-index: 2;
    padding: 25px;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    color: var(--light-slate);
    font-size: var(--fz-lg);

    @media (max-width: 768px) {
      padding: 20px 0;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }

    strong {
      color: var(--white);
      font-weight: normal;
    }
  }

  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0 10px;
    padding: 0;
    list-style: none;

    li {
      margin: 0 20px 5px 0;
      color: var(--light-slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      margin: 10px 0;

      li {
        margin: 0 10px 5px 0;
        color: var(--lightest-slate);
      }
    }
  }

  .project-links {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-left: -10px;
    color: var(--lightest-slate);

    a {
      ${({ theme }) => theme.mixins.flexCenter};
      padding: 10px;

      &.external {
        svg {
          width: 20px;
          height: 20px;
          margin-top: -2px;
        }
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .cta {
      ${({ theme }) => theme.mixins.smallButton};
      margin: 10px;
    }
  }

  .project-image {
    ${({ theme }) => theme.mixins.boxShadow};
    grid-column: 6 / -1;
    grid-row: 1 / -1;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      grid-column: 1 / -1;
      height: 100%;
      opacity: 0.25;
    }

    a {
      width: 100%;
      height: 100%;
      background-color: var(--green);
      border-radius: var(--border-radius);
      vertical-align: middle;

      &:hover,
      &:focus {
        background: transparent;
        outline: 0;

        &:before,
        .img {
          background: transparent;
          filter: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        transition: var(--transition);
        background-color: var(--navy);
        mix-blend-mode: screen;
      }
    }

    .img {
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1) brightness(90%);

      @media (max-width: 768px) {
        object-fit: cover;
        width: auto;
        height: 100%;
        filter: grayscale(100%) contrast(1) brightness(50%);
      }
    }
  }
`;

// Styled Components for Show More Functionality

// 1. Centered Container for Buttons/Links
const CenteredContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

// 2. Show More Button Styled as Link
const ShowMoreButton = styled.button`
  background: none;
  border: none;
  color: var(--green);
  font-size: var(--fz-lg);
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: var(--green);
    text-decoration: underline;
    outline: none;
  }
`;

// 3. Achievements List
const AchievementsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
`;

// 4. Achievement Item
const AchievementItem = styled.li`
  border-bottom: 1px solid var(--light-slate);
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;

  &:hover,
  &:focus {
    background-color: var(--navy);
  }

  /* Remove blue highlight on mobile */
  -webkit-tap-highlight-color: transparent;
  outline: none;

  /* Improve focus styles for accessibility */
  &:focus-visible {
    outline: 2px solid var(--green);
    outline-offset: 2px;
  }
`;

// 5. Achievement Header
const AchievementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// 6. Expand Icon
const ExpandIcon = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: var(--lightest-slate);
`;

// 7. Achievement Details
const AchievementDetails = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.4s ease, opacity 0.4s ease;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  margin-top: ${({ isOpen }) => (isOpen ? '10px' : '0')};
  color: var(--light-slate);

  p {
    margin: 5px 0;
  }
`;

// 8. Redirect Link Styled Component
const RedirectLink = styled.a`
  color: var(--green);
  font-size: var(--fz-sm);
  text-decoration: underline;
  margin-top: 8px;
  
  display: inline-block;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: var(--lightest-green);
    text-decoration: none;
    outline: none;
  }
`;

const Featured = () => {
  const revealArchiveLink = useRef(null);
  const data = useStaticQuery(graphql`
    {
      featured: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/featured/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              cover {
                childImageSharp {
                  gatsbyImageData(width: 700, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              tech
              github
              external
              cta
            }
            html
          }
        }
      }
    }
  `);

  const featuredProjects = data.featured.edges.filter(({ node }) => node);
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  // Define Achievements Data Directly in the Component
  const achievements = [
    {
      title: "Global Rank 927 @ LeetCode Biweekly Contest 132",
      date: "August 2024",
      description: "Achieved a global rank of 927 in LeetCode's Biweekly Contest 132",
      details: "❖ Ranked among top performers globally in a competitive coding environment.",
      link: "https://leetcode.com/contest/biweekly-contest-132/ranking/38/"
    },
    {
      title: "All India Rank 135: Coding Ninjas Code Combat 3.0",
      date: "March 2024",
      description: "Secured an All India Rank of 135 in Code Combat 3.0, a national-level competitive programming contest by Coding Ninjas.",
      details: "❖ Proven proficiency in problem-solving under time constraints at a high national ranking.",
      link: "https://ninjasfiles.s3.amazonaws.com/certificate_of_event_code_combat_3_0_8310ad1f645555ec6a1b.pdf"
    },
    {
      title: "Nexathon 4.0 Hackathon Winner",
      date: "April 2024",
      description: "Won Nexathon 4.0 Hackathon at RGIT",
      details: "❖ Developed a GenAI platform for Educational Institutions",
      link: "https://drive.google.com/drive/folders/1piG1tBaS5vC8BoqZVbKHJ5km8aiPW2m8?usp=sharing"
    },
    {
      title: "Solidity Scan Track Bounty Winner @ Hackspiration'24 Hackathon",
      date: "May 2024",
      description: "Won the Solidity Scan Track Bounty at Hackspiration'24",
      details: "❖ Developed a Blockchain based solution for under construction projects",
      link: "https://devfolio.co/projects/keynest-1794"
    },
    {
      title: "Rank 1: HackerRank SCC-3.0",
      date: "February 2024",
      description: "Achieved 1st place in the Shastra Challenge 3.0 contest on HackerRank, proving strong command over competitive coding skills.",
      details: "❖ Topped the leaderboard with high scores in challenging coding problems (@shahsai11111)",
      link: "https://www.hackerrank.com/contests/shastra-challenge-3-contest-a/leaderboard"
    },
    {
      title: "Quira Project Quest Winner × 4",
      date: "June 2024",
      description: "Won four Quira Project Quests during Social Summer of Code",
      details: "❖ Displayed a consistent track record of project success through multiple wins in Quira's project-based contests.",
      link: "https://quira.sh/user/AI-ApeX-DeV"
    }
  ];
  

  const [showMore, setShowMore] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, [prefersReducedMotion]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const toggleItem = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <section id="featured">
      <StyledNumberedHeading ref={revealTitle}>
        Some Cool Achievements
      </StyledNumberedHeading>

      <StyledProjectsGrid>
        {featuredProjects &&
          featuredProjects.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { external, title, tech, github, cover, cta } = frontmatter;
            const image = getImage(cover);

            return (
              <StyledProject key={i} ref={(el) => (revealProjects.current[i] = el)}>
                <div className="project-content">
                  <div>
                    <p className="project-overline">Featured Project</p>

                    <h3 className="project-title">
                      <a href={external} target="_blank" rel="noopener noreferrer">
                        {title}
                      </a>
                    </h3>

                    <div
                      className="project-description"
                      dangerouslySetInnerHTML={{ __html: html }}
                    />

                    {tech.length > 0 && (
                      <ul className="project-tech-list">
                        {tech.map((techItem, idx) => (
                          <li key={idx}>{techItem}</li>
                        ))}
                      </ul>
                    )}

                    <div className="project-links">
                      {cta && (
                        <a href={cta} aria-label="Course Link" className="cta" target="_blank" rel="noopener noreferrer">
                          Learn More
                        </a>
                      )}
                      {github && (
                        <a href={github} aria-label="GitHub Link" target="_blank" rel="noopener noreferrer">
                          <Icon name="GitHub" size="20px" />
                        </a>
                      )}
                      {external && !cta && (
                        <a href={external} aria-label="External Link" className="external" target="_blank" rel="noopener noreferrer">
                          <Icon name="External" size="20px" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>

                <div className="project-image">
                  <a href={external || github || '#'} target="_blank" rel="noopener noreferrer">
                    <GatsbyImage image={image} alt={title} className="img" />
                  </a>
                </div>
              </StyledProject>
            );
          })}
      </StyledProjectsGrid>

      {/* Show More Button */}
      <CenteredContainer>
        <ShowMoreButton
          onClick={toggleShowMore}
          aria-expanded={showMore}
          aria-controls="achievements-list"
        >
          {showMore ? 'Show Less' : 'Show More'}
        </ShowMoreButton>
      </CenteredContainer>

      {/* Achievements List */}
      {showMore && (
        <AchievementsList id="achievements-list">
          {achievements.map((achievement, index) => (
            <AchievementItem
              key={index}
              onClick={() => toggleItem(index)}
              role="button"
              tabIndex={0}
              aria-expanded={expandedItems[index] || false}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') toggleItem(index);
              }}
            >
              <AchievementHeader>
                <span>{achievement.title}</span>
                <ExpandIcon>{expandedItems[index] ? '−' : '+'}</ExpandIcon>
              </AchievementHeader>
              <AchievementDetails isOpen={expandedItems[index]}>
                <p><strong>❖ </strong> {achievement.description}</p>
                {achievement.details && <p>{achievement.details}</p>}
                {achievement.link && (
                  <RedirectLink href={achievement.link} target="_blank" rel="noopener noreferrer">
                    Learn More
                  </RedirectLink>
                )}
              </AchievementDetails>
            </AchievementItem>
          ))}
        </AchievementsList>
      )}

      <br />
      <div style={{ textAlign: 'center' }}>
        <Link
          className="inline-link archive-link"
          to="/archive"
          ref={revealArchiveLink}
          style={{ display: 'inline-block' }}
        >
          View the Archive
        </Link>
      </div>
    </section>
  );
};

export default Featured;