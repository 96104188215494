// src/components/AchievementsTimeline.js

import React, { useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components'; // Correct import
import { useStaticQuery, graphql } from 'gatsby';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

// Define FadeIn Animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components

const StyledAchievementsSection = styled.section`
  max-width: 900px;
  margin: 0 auto;
  padding: 4rem 0;
  
  h2.numbered-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(24px, 5vw, var(--fz-heading));
    width: 100%;
    margin: 40px 0; /* Adjust margin as needed */

    /* Pseudo-elements for horizontal lines */
    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid SlateGray; /* Adjust color and thickness as needed */
      margin: 0 15px; /* Space between lines and text */
    }
  }

  .heading {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    position: relative;

    &::after {
      content: '';
      width: 60px;
      height: 4px;
      background: var(--green);
      display: block;
      margin: 0.75rem auto 0;
      border-radius: 2px;
    }
  }
`;

const Timeline = styled.ol`
  position: relative;
  padding: 0;
  list-style: none;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 4px;
    background: var(--lightest-navy);
    transform: translateX(-50%);
  }

  @media (max-width: 768px) {
    &::before {
      left: 20px;
      transform: none;
    }
  }
`;

const TimelineItem = styled.li`
  position: relative;
  width: 50%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  transition: background 0.3s ease;

  &:nth-child(odd) {
    left: 0;
    text-align: right;
  }

  &:nth-child(even) {
    left: 50%;
    text-align: left;
  }

  &:nth-child(odd)::before {
    content: '';
    position: absolute;
    top: 1.5rem;
    right: -8px;
    width: 16px;
    height: 16px;
    background: var(--green);
    border: 4px solid var(--lightest-navy);
    border-radius: 50%;
    z-index: 1;
  }

  &:nth-child(even)::before {
    content: '';
    position: absolute;
    top: 1.5rem;
    left: -8px;
    width: 16px;
    height: 16px;
    background: var(--green);
    border: 4px solid var(--lightest-navy);
    border-radius: 50%;
    z-index: 1;
  }

  .content {
    background: var(--lightest-navy);
    padding: 1.5rem;
    border-radius: var(--border-radius);
    position: relative;
    transition: background 0.3s ease, max-height 0.3s ease;
    /* Initial state for animation */
    opacity: 0;
    transform: translateY(20px);

    /* Conditional Animation or Visibility */
    ${props =>
      !props.prefersReducedMotion
        ? css`
            animation: ${fadeIn} 0.5s ease-in-out forwards;
          `
        : css`
            opacity: 1;
            transform: translateY(0);
          `}

    &:hover,
    &:focus-within {
      background: var(--navy);
    }

    &:hover .description,
    &:focus-within .description {
      max-height: 500px; /* Adjust as needed */
    }
  }

  .title {
    font-size: var(--fz-lg);
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--green);
  }

  .company {
    font-size: var(--fz-md);
    color: var(--slate);
    margin-bottom: 0.5rem;
  }

  .range {
    font-size: var(--fz-sm);
    color: var(--light-slate);
    margin-bottom: 0.5rem;
  }

  .location {
    font-size: var(--fz-sm);
    color: var(--light-slate);
  }

  .description {
    margin-top: 1rem;
    font-size: var(--fz-sm);
    color: var(--light-slate);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
    text-align: left;
    padding-left: 3rem;
    margin-bottom: 2rem;

    &:nth-child(odd),
    &:nth-child(even) {
      left: 0;
    }

    &:nth-child(odd)::before,
    &:nth-child(even)::before {
      left: 12px;
    }
  }
`;

const AchievementsTimeline = () => {
  const data = useStaticQuery(graphql`
    query {
      achievements: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/Education/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              location
              range
              url
            }
            html
          }
        }
      }
    }
  `);

  const achievementsData = data.achievements.edges;

  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal('.timeline-item', srConfig());
  }, [prefersReducedMotion]);

  return (
    <StyledAchievementsSection id="education">
       <h2 className="numbered-heading">Education</h2>
      <Timeline>
        {achievementsData.map(({ node }, index) => {
          const { title, company, location, range, url} = node.frontmatter;

          return (
            <TimelineItem
              key={index}
              className="timeline-item"
              prefersReducedMotion={prefersReducedMotion} // Pass as prop
            >
              <div className="content" tabIndex="0">
                <h3 className="title">
                  {title} {' '}
                  <a
                    href={url}
                    className="inline-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {company}
                  </a>
                </h3>
                <p className="range">{range}</p>
                <p className="location">{location}</p>
                
                <div
                  id={`achievement-details-${index}`}
                  className="description"
                  dangerouslySetInnerHTML={{ __html: node.html }}
                />
              </div>
            </TimelineItem>
          );
        })}
      </Timeline>
    </StyledAchievementsSection>
  );
};

export default AchievementsTimeline;