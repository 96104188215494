module.exports = {
  email: 'shahsai11111@gmail.com',

  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/AI-ApeX-DeV',
    },
    {
      name: 'Twitter',
      url: 'https://x.com/shahsai11111',
    },
    {
      name: 'Linkedin',
      url: 'https://linkedin.com/in/sharukhali-farookali-naziyabegum-syed-2817bb272',
    },
    {
      name: 'Instagram',
      url: 'https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52',
    },
    {
      name: 'Codepen',
      url: 'https://quira.sh/user/AI-ApeX-DeV',
    }
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Education',
      url: '/#education',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Volunteer',
      url: '/#volunteer',
    },
    {
      name: 'Achievements',
      url: '/#featured',
    },
    {
      name: 'Certification',
      url: '/#certifications',
    },
    {
      name: 'Research',
      url: '/#research',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
