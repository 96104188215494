// src/components/sections/certifications.js

import React, { useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { srConfig } from '@config';
import img1 from '../../../public/certifications/img1.png';
import img2 from '../../../public/certifications/img2.png';
import img3 from '../../../public/certifications/img3.jpg';
import img4 from '../../../public/certifications/img4.png';
import img5 from '../../../public/certifications/img5.png';
import img6 from '../../../public/certifications/img6.png';
import img7 from '../../../public/certifications/img7.jpg';
import img8 from '../../../public/certifications/img8.png';
import img9 from '../../../public/certifications/img9.png';
import img10 from '../../../public/certifications/img10.png';

import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper modules
import { Navigation, Pagination, A11y, Autoplay, EffectCoverflow, Mousewheel } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/mousewheel';

// Optional: Define FadeIn Animation for future use
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Dummy Certification Data (10 Certifications)
const certifications = [
  // ... (Your existing certifications data remains unchanged)
  {
    id: 1,
    name: 'PRDV151: Bitcoin for Everybody',
    issuer: 'Saylor Academy',
    image: img1,
    date: 'March 2023',
    description: 'Introductory course on Bitcoin, its uses, and implications in finance.',
    url: 'https://learn.saylor.org/admin/tool/certificate/index.php?code=6962526421SS',
  },
  {
    id: 2,
    name: 'CS120: Bitcoin for Developers I',
    issuer: 'Saylor Academy',
    image: img2,
    date: 'March 2023',
    description: 'Technical course focused on Bitcoin for developers, covering underlying technology and blockchain.',
    url: 'https://learn.saylor.org/admin/tool/certificate/index.php?code=8802874706SS',
  },
  {
    id: 3,
    name: 'Artificial Intelligence: Search Methods for Problem Solving',
    issuer: 'Indian Institute of Technology, Madras',
    image: img3,
    date: 'October 2022',
    description: 'Focused on search methods in AI for solving complex computational problems.',
    url: 'https://nptel.ac.in/noc/E_Certificate/NPTEL22CS67S4358216510104297',
  },
  {
    id: 4,
    name: 'Essential Mathematics for Machine Learning',
    issuer: 'Indian Institute of Technology, Roorkee',
    image: img4,
    date: 'October 2022',
    description: 'Covers foundational mathematics essential for machine learning applications.',
    url: 'https://nptel.ac.in/noc/E_Certificate/NPTEL22MA40S6358199910104297',
  },
  {
    id: 5,
    name: 'TensorFlow: Advanced Techniques Specialization',
    issuer: 'DeepLearning.AI',
    image: img5,
    date: 'April 2023',
    description: 'Advanced techniques for building, optimizing, and deploying TensorFlow models.',
    url: 'https://www.coursera.org/account/accomplishments/specialization/certificate/5UTEBPVZQJKQ',
  },
  {
    id: 6,
    name: 'Data Analytics with Python',
    issuer: 'Indian Institute of Technology, Roorkee',
    image: img6,
    date: 'April 2023',
    description: 'Comprehensive course on data analytics using Python, focusing on data processing and visualization.',
    url: 'https://nptel.ac.in/noc/E_Certificate/NPTEL23CS08S6422234104265791',
  },
  {
    id: 7,
    name: 'Reinforcement Learning Specialization',
    issuer: 'University of Alberta',
    image: img7,
    date: 'April 2023',
    description: 'In-depth specialization on reinforcement learning techniques and applications.',
    url: 'https://www.coursera.org/account/accomplishments/specialization/certificate/959DFU6B2FTT',
  },
  {
    id: 8,
    name: 'Google Data Analytics Specialization',
    issuer: 'Google',
    image: img8,
    date: 'April 2023',
    description: 'Data analytics specialization covering data cleaning, analysis, and visualization using Google tools.',
    url: 'https://www.coursera.org/account/accomplishments/specialization/certificate/LDAW3V7P825M',
  },
  {
    id: 9,
    name: 'Google Project Management: Specialization',
    issuer: 'Google',
    image: img9,
    date: 'September 2023',
    description: 'Covers project management fundamentals and applications, with a focus on real-world skills.',
    url: 'https://www.coursera.org/account/accomplishments/specialization/certificate/7SXK2KUFQLLC',
  },
  {
    id: 10,
    name: 'Professional Data Scientist',
    issuer: 'DataCamp',
    image: img10,
    date: 'October 2023',
    description: 'Data science professional certification covering data analysis, visualization, and machine learning.',
    url: 'https://www.datacamp.com/certificate/DS0027950377222',
  }

];

// Styled Components

const StyledCertificationsSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;

  h2.numbered-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(24px, 5vw, var(--fz-heading));
    width: 100%;
    margin: 40px 0;

    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid SlateGray;
      margin: 0 15px;
    }
  }

  .heading {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
    position: relative;
    color: var(--green);

    &::after {
      content: '';
      width: 60px;
      height: 3px;
      background: var(--green);
      display: block;
      margin: 0.5rem auto 0;
      border-radius: 2px;
    }
  }

  /* Swiper Navigation and Pagination Styles */
  .swiper-button-prev,
  .swiper-button-next {
    color: var(--green);
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px; /* Increased horizontal margin for better spacing */

    &::after {
      font-size: 20px;
      font-weight: bold;
    }

    &:hover {
      background: var(--navy);
      color: var(--light-green);
    }
  }

  /* Position adjustments for larger screens */
  .swiper-button-prev {
    left: 15px;
  }

  .swiper-button-next {
    right: 15px;
  }

  .swiper-pagination {
    margin-top: 70px; /* Increased vertical space between cards and pagination dots */
    bottom: 10px !important; /* Ensure pagination is positioned correctly */
  }

  .swiper-pagination-bullet {
    background: var(--green);
    opacity: 0.7;
    width: 12px;
    height: 12px;
    margin: 0 8px !important; /* Increased spacing between bullets */
    transition: background 0.3s, opacity 0.3s;
  }

  .swiper-pagination-bullet-active {
    background: var(--light-green);
    opacity: 1;
  }

  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .swiper-button-prev,
    .swiper-button-next {
      width: 35px;
      height: 35px;
      margin: 0 10px; /* Reduced horizontal margin for mobile */
    }

    .swiper-button-prev {
      left: 10px; /* Adjusted positioning */
    }

    .swiper-button-next {
      right: 10px; /* Adjusted positioning */
    }

    .swiper-pagination {
      margin-top: 50px; /* Slightly reduced margin for smaller screens */
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 16px !important; /* Adjusted spacing between bullets */
    }
  }

  @media (max-width: 480px) {
    .swiper-button-prev,
    .swiper-button-next {
      width: 30px;
      height: 30px;
      margin: 0 8px; /* Further reduced margin for very small screens */
    }

    .swiper-button-prev {
      left: 18px; /* Further adjusted positioning */
    }

    .swiper-button-next {
      right: 18px; /* Further adjusted positioning */
    }

    .swiper-pagination {
      margin-top: 25px; /* Further reduced margin for smallest screens */
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 4px !important; /* Further adjusted spacing between bullets */
    }
  }
`;

// CertificationCard styled component
const CertificationCard = styled.a`
  display: flex;
  flex-direction: column;
  background: var(--lightest-navy);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;

  opacity: 0;
  transform: translateY(20px);

  ${props =>
    !props.prefersReducedMotion
      ? css`
          animation: ${fadeIn} 0.5s ease-in-out forwards;
        `
      : css`
          opacity: 1;
          transform: translateY(0);
        `}

  &:hover,
  &:focus {
    background: var(--navy);
    transform: scale(1.05) translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-left: 4px solid var(--green);
  }

  &:hover .image-container img,
  &:focus .image-container img {
    transform: scale(1.05);
  }

  .image-container {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    overflow: hidden;
    border-radius: var(--border-radius);
    background: #fff;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform 0.3s ease;
    }
  }

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;

      .title {
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 0.3rem;
        color: var(--green);
      }

      .issuer {
        font-size: 0.9rem;
        color: var(--slate);
      }
    }

    .date {
      font-size: 0.85rem;
      color: var(--light-slate);
      margin-bottom: 0.5rem;
    }

    .description {
      font-size: 0.85rem;
      color: var(--light-slate);
      line-height: 1.4;
      /* Limit to 3 lines */
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;

// Swiper styled component without fixed width
const CertificationsCarousel = styled(Swiper)`
  width: 100%;
  padding: 2rem 0;

  .swiper-slide {
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .swiper-slide-active {
    z-index: 2;
  }

  .swiper-slide-next,
  .swiper-slide-prev {
    z-index: 1;
  }

  /* Optional: Add shadow to next and prev slides for depth effect */
  .swiper-slide-next,
  .swiper-slide-prev {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const CertificationsListComponent = () => {
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal('.certification-card', srConfig());
  }, [prefersReducedMotion]);

  return (
    <StyledCertificationsSection id="certifications">
      <h2 className="numbered-heading">Certifications</h2>
      
      <CertificationsCarousel
        modules={[Navigation, Pagination, A11y, Autoplay, EffectCoverflow, Mousewheel]}
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        loop={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        navigation
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
      >
        {certifications.map(cert => (
          <SwiperSlide key={cert.id} style={{ width: '300px' }}>
            <CertificationCard
              href={cert.url}
              target="_blank"
              rel="noopener noreferrer"
              className="certification-card"
              prefersReducedMotion={prefersReducedMotion}
            >
              <div className="image-container">
                <img src={cert.image} alt={`${cert.name} logo`} />
              </div>
              <div className="details">
                <div className="header">
                  <h3 className="title">{cert.name}</h3>
                  <span className="issuer">{cert.issuer}</span>
                </div>
                <div className="date">{cert.date}</div>
                <div className="description">{cert.description}</div>
              </div>
            </CertificationCard>
          </SwiperSlide>
        ))}
      </CertificationsCarousel>
    </StyledCertificationsSection>
  );
};

export default CertificationsListComponent;