import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

// Remove keyframe animations since they are no longer needed
/*
const scrollLeftToRight = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const scrollRightToLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;
*/

const StyledAboutSection = styled.section`
  max-width: 900px;
  h2.numbered-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(24px, 5vw, var(--fz-heading));
    width: 100%;
    margin: 40px 0; /* Adjust margin as needed */

    /* Pseudo-elements for horizontal lines */
    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid SlateGray; /* Adjust color and thickness as needed */
      margin: 0 15px; /* Space between lines and text */
    }
  }


  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const StyledText = styled.div`
  padding: 1rem;

  div {
    margin-bottom: 2rem;
  }

  h5 {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    color: var(--green);
  }
`;

// Updated StyledSkillsList to display skills in a single row
const StyledSkillsList = styled.ul`
  display: flex;
  flex-wrap: nowrap; /* Ensure single row */
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-x: auto; /* Allow horizontal scrolling if needed */

  /* Optional: Hide scrollbar for a cleaner look */
  /* 
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For Internet Explorer and Edge

  &::-webkit-scrollbar {
    display: none; // For Chrome, Safari, and Opera
  }
  */

  li {
    margin-right: 2rem;
    /* Removed margin-bottom as wrapping is disabled */
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    position: relative;
    white-space: nowrap; /* Prevent text from wrapping */

    &:before {
      content: '▹';
      position: absolute;
      left: -1.5rem;
      color: var(--green);
      font-size: var(--fz-sm);
      line-height: 12px;
    }
  }

  /* Adjust padding to ensure proper alignment */
  padding-bottom: 0.5rem;
`;

const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, [prefersReducedMotion]); // Added prefersReducedMotion as a dependency

  const skills = {
    Languages: [
      'C/C++',
      'Java',
      'Python',
      'JavaScript',
      'TypeScript',
      'Solidity',
      'Rust',
      'Move',
    ],
    Databases: ['SQLite', 'PostgreSQL', 'MongoDB', 'Firebase'],
    DevOps: ['Docker', 'Jenkins', 'Kubernetes (K8s)'],
    CloudPlatforms: ['Google Cloud Platform (GCP)', 'Amazon Web Services (AWS)'],
    Frameworks: [
      'Next.js',
      'Node.js',
      'React.js',
      'Express.js',
      'Django',
      'Flask',
    ],
    Tools: [
      'Git',
      'Linux',
      'Cisco Packet Tracer',
      'Canva',
      'Blender',
      'Adobe Photoshop',
      'Figma',
    ],
    Concepts: [
      'Computer Networks',
      'Operating Systems',
      'System Design',
      'Microservices',
      'Cloud Computing',
    ],
  };

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I started my journey by participating in hackathons, events, and buildathons. I have also undertaken freelancing and internships as a Full Stack Developer.
            </p>

            <p>Here are a few technologies I’ve worked on:</p>
            <br />
          </div>

          {Object.keys(skills).map((category, index) => (
            <div key={index}>
              <h5>{category}</h5>
              <StyledSkillsList>
                {skills[category].map((skill, i) => (
                  <li key={i}>{skill}</li>
                ))}
              </StyledSkillsList>
            </div>
          ))}
        </StyledText>

        {/* If you have an image or additional content, include it here */}
        {/* <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/profile-pic.png"
              alt="Profile Picture"
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
            />
          </div>
        </StyledPic> */}
      </div>
    </StyledAboutSection>
  );
};

export default About;